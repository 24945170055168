import React from "react";
// import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";

// import { Container, Row, Col } from "react-bootstrap";
// import UnbiasedDesignPic from "../../Assets/UnbiasedDesign.png";

// import {AiOutlineFilePdf} from "react-icons/ai";
// import { BsGithub } from "react-icons/bs";
// import { MdOndemandVideo } from "react-icons/md";
function NewsCards(props) {
  return (
    <p className="home-about-body">

  </p>
  );
}
export default NewsCards;
